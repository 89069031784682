import * as React from "react";
import {Tab, Tabs} from "react-bootstrap";
import Layout from "../../layout";
import "./user.css";

// markup
const ProfilePage = () => {
  return (
    <Layout
      title={"Welcome to CORSnet"}
      description={
        "Our Continuously Operating Reference Station (CORS) network deliver incredibly accurate and reliable cent metric measurement with more reference stations around the country."
      }
      privatePage
    >
      <section className="profile container py-8 py-lg-16">
        <Tabs
          defaultActiveKey="user"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="user" title="User Details">
            <form action="" method="post">
              <div className="mb-4">
                <label htmlFor="name">Name *</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  name="company"
                  id="company"
                  placeholder="Company"
                  className="form-control"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email">Email *</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  className="form-control"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="phone">Phone Number *</label>
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  className="form-control"
                />
              </div>

              <div className="mb-4">
                <label htmlFor="address">Address *</label>
                <input
                  type="text"
                  name="adress 1"
                  id="address"
                  placeholder="Addresss Line 1*"
                  className="form-control mb-2"
                  required
                />
                <input
                  type="text"
                  name="address2"
                  id="address"
                  placeholder="Address Line 2(Optional)"
                  className="form-control mb-2"
                />
                <input
                  type="text"
                  name="city"
                  id="address"
                  placeholder="City"
                  className="form-control mb-2"
                  required
                />
              </div>

              <input
                type="submit"
                value="Update"
                className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
              />
            </form>
          </Tab>
          <Tab eventKey="password" title="Change Password">
            <form action="" method="post">
              <div className="mb-4">
                <label htmlFor="password">Current Password</label>
                <input
                  type="password"
                  name="current password"
                  id="Password"
                  placeholder="Current Password"
                  className="form-control mb-2"
                  minLength="6"
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password">New Password</label>
                <input
                  type="password"
                  name="new password"
                  id="Password"
                  placeholder="New Password (Please Use 6-10 Characters)"
                  className="form-control mb-2"
                  minLength="6"
                  required
                />
                <input
                  type="password"
                  name="new password"
                  id="PasswordConfirmation"
                  placeholder="Confirm Password"
                  className="form-control"
                  required
                />
              </div>
              <input
                type="submit"
                value="Update"
                className="btn btn-primary rounded-pill mb-4 ms-auto me-auto text-center d-block w-100"
              />
            </form>
          </Tab>
        </Tabs>
      </section>
    </Layout>
  );
};

export default ProfilePage;
